import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SamlConfig } from '../../../models/Account';
import AdminService from '../../../services/AdminService';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { Input } from '../../../components/shared/form-control/Input';
import MultiTextField from '../../../components/shared/form-control/MultiTextField';
import Checkbox from '../../../components/shared/form-control/Checkbox';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getContainingRoles, getHighestRole, Roles } from '../../../models/Role';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import CopyIcon from '../../../components/shared/icon/CopyIcon';
import LinkIcon from '../../../components/shared/icon/LinkIcon';
import Tooltip from '../../../components/shared/Tooltip';

const internalRoles = Object.values(Roles);

const validationRules: Record<keyof SamlConfig, boolean> = {
  redirectUrl: true,
  entityId: true,
  issuer: false,
  metadataUrl: true,
  singleSignOnUrl: false,
  singleLogoutUrl: false,
  signingCertificate: false,
  id: false,
  accountId: true,
  autoRegistration: false,
  roleMappings: false,
  active: false,
};

const AccountSamlConfig = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation(['accounts', 'common']);
  const [tooltipText, setTooltipText] = useState<string>(t('common:copy-text.tooltip'));
  const toasts = useToasts();

  const computedSamlValues = useMemo(
    () => ({
      redirectUrl: `${import.meta.env.VITE_API_BASE_URL}/v1/auth/${accountId}/login-with-saml`,
      entityId: `${import.meta.env.VITE_API_BASE_URL}/saml/sp/${accountId}`,
    }),
    [accountId],
  );

  const [formData, setFormData] = useState<SamlConfig>({
    id: '',
    accountId: '',
    autoRegistration: false,
    ...computedSamlValues,
    issuer: '',
    metadataUrl: '',
    singleSignOnUrl: '',
    singleLogoutUrl: '',
    signingCertificate: '',
    roleMappings: {},
    active: true,
  });
  const [initialFormData, setInitialFormData] = useState(formData);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [externalRoleInputs, setExternalRoleInputs] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!accountId) return;

    const fetchSamlConfig = async () => {
      try {
        const response = await AdminService.getSamlConfig(accountId);
        const data = response.data || { ...computedSamlValues };
        const roleMappings = data.roleMappings || {};

        setFormData({
          ...data,
          roleMappings: data.roleMappings || {},
          accountId,
        });

        const mappedInputs: Record<string, string> = {};

        Object.entries(roleMappings).forEach(([externalRole, internalRoles]) => {
          mappedInputs[getHighestRole(internalRoles.split(','))] = externalRole;
        });

        setExternalRoleInputs(mappedInputs);

        setInitialFormData({
          ...data,
          roleMappings: data.roleMappings || {},
          accountId,
        });
      } catch (error) {
        console.error('Failed to fetch SAML config:', error);
      }
    };

    fetchSamlConfig();
  }, [accountId, computedSamlValues]);

  const computeRoleMappings = useCallback(() => {
    const updatedMappings: Record<string, string> = {};

    Object.entries(externalRoleInputs).forEach(([internalRole, externalRole]) => {
      if (externalRole.trim()) {
        updatedMappings[externalRole] = getContainingRoles(internalRole as Roles).join(',');
      }
    });

    return updatedMappings;
  }, [externalRoleInputs]);

  const validateForm = useCallback(() => {
    const newErrors: Record<string, string> = {};
    const usedExternalRoles = new Set<string>();

    Object.keys(externalRoleInputs).forEach((role) => {
      const externalRole = externalRoleInputs[role]?.trim();
      if (externalRole) {
        if (usedExternalRoles.has(externalRole)) {
          newErrors[role] = t('saml-config.validation.duplicate-external-role');
        } else {
          usedExternalRoles.add(externalRole);
        }
      }
    });

    Object.keys(validationRules).forEach((field) => {
      if (validationRules[field] && !formData[field as keyof SamlConfig]?.toString().trim()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        newErrors[field] = t(`saml-config.validation.${field}-required` as any);
      }
    });

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);

    // Compute current role mappings
    const computedMappings = computeRoleMappings();

    // Normalize initial role mappings (ensure consistent object shape)
    const normalizedInitialMappings = initialFormData.roleMappings || {};

    // Compare full formData + roleMappings to detect any changes
    setIsFormChanged(
      JSON.stringify({ ...formData, roleMappings: computedMappings }) !==
        JSON.stringify({ ...initialFormData, roleMappings: normalizedInitialMappings }),
    );
  }, [externalRoleInputs, formData, initialFormData, computeRoleMappings, t]);

  useEffect(() => {
    validateForm();
  }, [formData, validateForm]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { name, value } = e.target;

      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (errors[name]) {
        setErrors((prev) => ({
          ...prev,
          [name]: '',
        }));
      }
    },
    [errors],
  );

  const handleExternalRoleChange = useCallback((internalRole: Roles, value: string) => {
    setExternalRoleInputs((prev) => ({
      ...prev,
      [internalRole]: value,
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (!isFormValid) return;

    const updatedRoleMappings = computeRoleMappings();

    const payload = {
      ...formData,
      roleMappings: Object.keys(updatedRoleMappings).length === 0 ? null : updatedRoleMappings,
    };

    AdminService.upsertSamlConfig(payload).then((response) => {
      setFormData(response.data);
      setInitialFormData(response.data);
    });
  }, [isFormValid, computeRoleMappings, formData]);

  const copyLink = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text);
      setTooltipText(t('common:copy-text.success'));
      toasts.addToast({
        type: ToastType.INFO,
        title: t('common:copy-text.success'),
        expiresInMs: 2000,
      });
      setTimeout(() => {
        setTooltipText(t('common:copy-text.tooltip'));
      }, 2000);
    },
    [t, toasts],
  );

  const loginUrl = useMemo(() => `${window.location.origin}/auth/${formData.accountId}/saml-login`, [formData.accountId]);

  return (
    <div className="w-full">
      <div className="my-6 flex justify-between">
        <Heading size={HeadingSize.H3}>{t('saml-config.title')}</Heading>
        <div className="mt-4 flex justify-end">
          <Button data-cy="save" type={ButtonType.PRIMARY} onClick={handleSubmit} disabled={!isFormValid || !isFormChanged}>
            {t('saml-config.save-button')}
          </Button>
        </div>
      </div>

      <div className="flex gap-8">
        <div className="flex w-2/3 flex-col">
          <h3 className="font-bold">{t('saml-config.details-title')}</h3>
          {formData.id && (
            <div className="mt-4 flex flex-col">
              <span className="text-dpm-12">Client Login URL</span>
              <span>
                <Tooltip text={tooltipText}>
                  {(tooltip) => (
                    <div {...tooltip} className="relative cursor-pointer text-blue-500" onClick={() => copyLink(loginUrl)}>
                      <span className="text-blue-500 underline">{loginUrl}</span> <CopyIcon className="mr-2 h-5 w-5" />
                    </div>
                  )}
                </Tooltip>
              </span>
            </div>
          )}
          <Input
            type="text"
            name="redirectUrl"
            placeholder={t('saml-config.redirect-url')}
            label={t('saml-config.redirect-url')}
            value={formData.redirectUrl}
            onChange={handleChange}
            disabled
          >
            <Input.Slot name="leading">{<LinkIcon className="ml-2 h-4 w-4" />}</Input.Slot>
            <Input.Slot name="trailing">
              {
                <Tooltip text={tooltipText}>
                  {(tooltip) => (
                    <div {...tooltip} className="relative cursor-pointer" onClick={() => copyLink(formData.redirectUrl)}>
                      <CopyIcon className="mr-2 h-5 w-5" />
                    </div>
                  )}
                </Tooltip>
              }
            </Input.Slot>
          </Input>
          <Input
            type="text"
            name="entityId"
            placeholder={t('saml-config.entity-id')}
            label={t('saml-config.entity-id')}
            value={formData.entityId}
            onChange={handleChange}
            error={errors.entityId}
            errorState={!!errors.entityId}
            disabled
          >
            <Input.Slot name="leading">{<LinkIcon className="ml-2 h-4 w-4" />}</Input.Slot>
            <Input.Slot name="trailing">
              {
                <Tooltip text={tooltipText}>
                  {(tooltip) => (
                    <div {...tooltip} className="relative cursor-pointer" onClick={() => copyLink(formData.entityId)}>
                      <CopyIcon className="mr-2 h-5 w-5" />
                    </div>
                  )}
                </Tooltip>
              }
            </Input.Slot>
          </Input>
          <Input
            type="text"
            name="issuer"
            placeholder={t('saml-config.issuer')}
            label={t('saml-config.issuer')}
            value={formData.issuer}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="metadataUrl"
            placeholder={t('saml-config.metadata-url')}
            label={t('saml-config.metadata-url')}
            value={formData.metadataUrl}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="singleSignOnUrl"
            placeholder={t('saml-config.single-sign-on-url')}
            label={t('saml-config.single-sign-on-url')}
            value={formData.singleSignOnUrl}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="singleLogoutUrl"
            placeholder={t('saml-config.single-logout-url')}
            label={t('saml-config.single-logout-url')}
            value={formData.singleLogoutUrl}
            onChange={handleChange}
          />
          <MultiTextField
            name="signingCertificate"
            placeholder={t('saml-config.signing-certificate')}
            label={t('saml-config.signing-certificate')}
            value={formData.signingCertificate}
            onChange={handleChange}
          />

          <Checkbox
            label={t('saml-config.auto-registration')}
            value={formData.autoRegistration}
            onChange={(val) => setFormData((prev) => ({ ...prev, autoRegistration: val }))}
          />
          <Checkbox label={t('saml-config.active')} value={formData.active} onChange={(val) => setFormData((prev) => ({ ...prev, active: val }))} />
        </div>

        <div className="flex w-1/3 flex-col">
          <h3 className="mb-8 font-bold">{t('saml-config.role-mappings-title')}</h3>
          {internalRoles.map((internalRole) => (
            <div key={internalRole} className="mb-4 flex items-center space-x-4">
              <span className="w-1/3 whitespace-nowrap font-medium">{t(`common:roles.${internalRole}`)}</span>
              <Input
                type="text"
                placeholder={t('saml-config.external-role-placeholder')}
                value={externalRoleInputs[internalRole] || ''}
                onChange={(e) => handleExternalRoleChange(internalRole, e.target.value)}
                error={errors[internalRole]}
                errorState={!!errors[internalRole]}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountSamlConfig;
