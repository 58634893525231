import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useBusy from '../../../hooks/useBusy';
import TopNavPortal from '../../../components/layout/top-menu/TopNavPortal';
import { TabStrip } from '../../../components/shared/tab-strip/TabStrip';
import OrgDetails from '../../../components/my-org/OrgDetails';
import ClientService from '../../../services/ClientService';
import { Client } from '../../../models/Client';
import Users from './Users';
import StaticBreadCrumb from '../../../components/shared/breadcumb/StaticBreadCrumb';
import AccountSaml from './AccountSaml';

const AccountDetail = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const [client, setClient] = useState<Client>();
  const { t } = useTranslation(['accounts', 'organisation', 'accounts', 'common']);
  const { isBusy, withBusy } = useBusy();

  useEffect(() => {
    // fetch account details
    if (accountId) {
      withBusy(
        'get-account',
        ClientService.getClient(accountId).then((response) => {
          setClient(response);
        }),
      );
    }
  }, [accountId, withBusy]);

  return (
    <div className="h-full">
      <TabStrip headerClassName="-mb-2.5" contentClassName="bg-background-1" enableHash enableSticky wrapper={TopNavPortal}>
        <TabStrip.TabHeader id="details" text={t('details.tabs.details')} value={null} data-cy="account-tab" />
        <TabStrip.TabHeader id="saml" text={t('details.tabs.saml')} value={null} data-cy="saml-tab" />
        <TabStrip.TabHeader id="members" text={t('details.tabs.members')} value={null} data-cy="members-tab" />

        <TabStrip.TabContent forId="details" data-cy="account-tab-content">
          <div className="px-6">{client && !isBusy('get-account') && <OrgDetails client={client} />}</div>
        </TabStrip.TabContent>
        <TabStrip.TabContent forId="saml" data-cy="saml-tab-content">
          <div className="px-6">
            <AccountSaml />
          </div>
        </TabStrip.TabContent>
        <TabStrip.TabContent forId="members" data-cy="members-tab-content">
          <div className="px-6">
            <Users />
          </div>
        </TabStrip.TabContent>
      </TabStrip>
    </div>
  );
};

export default AccountDetail;
